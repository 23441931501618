import './Intro.scss';
import ReactTypingEffect from 'react-typing-effect';
import Lottie from 'react-lottie-player';
import { useResizeDetector } from 'react-resize-detector';

import DeveloperAnimation from '../animations/developer.json';

const IntroSection = (props) => {

    const { width, height, ref } = useResizeDetector();

    let size = 0;
    if (width < 600) {
        size = 175;
    } else if (width >= 600 && width < 768) {
        size = 240;
    } else if (width >= 768 && width < 992) {
        size = 280;
    }
    else {
        size = 400;
    }

    return (
        <div ref={ref} className="intro-section" style={{ height: `${props.appWindowInnetHeight}px` }}>
            <div className="contents">
                <div className="intro-text">
                    <div className="about-text">
                        Hi, I am <span className="bold">Sayan Das</span>
                    </div>
                    <div className="skill-text">
                        <ReactTypingEffect
                            text={[
                                'UI/UX Designer',
                                'Full-Stack Developer',
                                'Hybrid Mobile App Developer',
                                'Cloud Infrastructure Engineer',
                                'IoT Enthusiast',
                                'Landscape Photographer',
                                'Film Score Composer',
                                'Mix & Mastering Engineer',
                            ]}
                            speed={80}
                            eraseSpeed={60}
                            eraseDelay={1200}
                        />
                    </div>
                </div>
                <div className="animation-player">
                    <Lottie
                        animationData={DeveloperAnimation}
                        style={{ width: size, height: size }}
                        loop={true}
                        play={true}
                    />
                </div>
            </div>
            <div className="footer">
                Scroll down
                &nbsp;
                <i className="las la-caret-down icon-animation" />
            </div>


        </div>
    );
}

export default IntroSection;
