import './App.scss';

import IntroSection from './sections/Intro';

function App() {

  const appWindowInnetHeight = window.innerHeight > 400 ? window.innerHeight : 400;

  return (
    <div className="App">
      <IntroSection appWindowInnetHeight={appWindowInnetHeight} />
    </div>
  );
}

export default App;
